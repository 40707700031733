import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Head from '../../layouts/head';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { SDivider30, SDivider100 } from '../../styles/dividerStyles';

export default function CancelOrder() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('payment_cancel-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
      />
      <Header/>
      <SDivider30/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  const { t } = useTranslation();

  return(
    <main className='container text-center'>
      <h1>{t('payment_cancel-h1')}</h1>
      <SDivider30/>

      <p>
        {t('payment_cancel-main_1')}
        <br/><br/>
        <Link to={t('url-cart')}>{t('payment_cancel-main_2')}</Link>
      </p>
    </main>
  );
}