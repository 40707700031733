import languages from "./languages";
import * as us from '../assets/locales/us/translation.json';
/* import * as it from '../assets/locales/it/translation.json'; */

const i18InitConfig = {
  supportedLngs: languages,
  fallbackLng: "en-US",
  detection: {
    order: ['path', 'cookie', 'htmlTag'],
    caches: ['cookie'],
  },
  resources: {
    /* it: { translation: it }, */
    "en-US": { translation: us }
  },
  react: { useSuspense: true }
}

export default i18InitConfig;