import { createContext, useState } from "react";

import {
  isLetters,
  isAlphanumberic,
  isValidFiscalCode,
  isValidZip
} from '../../utils/regex';

export const InvoiceContext = createContext(null);

export const InvoiceContextProvider = (props) => {
  const [fiscalCode, setFiscalCode] = useState('');
  const [validFiscalCode, setValidFiscalCode] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [validFirstName, setValidFirstName] = useState(false);
  const [lastName, setLastName] = useState('');
  const [validLastName, setValidLastName] = useState(false);
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [validCity, setValidCity] = useState(false);
  const [zip, setZip] = useState('');
  const [validZip, setValidZip] = useState(false);
  const [address, setAddress] = useState('');
  const [validAddress, setValidAddress] = useState(false);

  const setInvoiceFiscalCode = (fiscalCode) => {
    setFiscalCode(fiscalCode);
    isValidFiscalCode(fiscalCode) ? setValidFiscalCode(true) : setValidFiscalCode(false);
  }
  const getInvoiceFiscalCode = () => {
    return fiscalCode;
  }
  const isValidInvoiceFiscalCode = () => {
    return validFiscalCode;
  }


  const setInvoiceName = (name) => {
    setFirstName(name);
    isLetters(name) ? setValidFirstName(true) : setValidFirstName(false);
  }
  const getInvoiceName = () => {
    return firstName;
  }
  const isValidInvoiceFirstName = () => {
    return validFirstName;
  }

  const setInvoiceLastName = (lastName) => {
    setLastName(lastName);
    isLetters(lastName) ? setValidLastName(true) : setValidLastName(false);
  }
  const getInvoiceLastName = () => {
    return lastName;
  }
  const isValidInvoiceLastName = () => {
    return validLastName;
  }

  const setInvoiceState = (state) => {
    setState(state);
  }
  const getInvoiceState = () => {
    return state;
  }

  const setInvoiceCountry = (country) => {
    setCountry(country);
  }
  const getInvoiceCountry = () => {
    return country;
  }

  const setInvoiceCity = (city) => {
    setCity(city);
    isLetters(city) ? setValidCity(true) : setValidCity(false);
  }
  const getInvoiceCity = () => {
    return city;
  }
  const isValidInvoiceCity = () => {
    return validCity;
  }

  const setInvoiceZip = (zip) => {
    setZip(zip);
    isValidZip(zip) ? setValidZip(true) : setValidZip(false);
  }
  const getInvoiceZip = () => {
    return zip;
  }
  const isValidInvoiceZip = () => {
    return validZip;
  }

  const setInvoiceAddress = (address) => {
    setAddress(address);
    isAlphanumberic(address) ? setValidAddress(true) : setValidAddress(false);
  }
  const getInvoiceAddress = () => {
    return address;
  }
  const isValidInvoiceAddress = () => {
    return validAddress;
  }

  const isValidInvoiceData = () => {
    if (validFiscalCode &&
      validFirstName &&
      validLastName &&
      validCity &&
      validZip &&
      validAddress
    ) {
      return true;
    } else {
      return false;
    }
  }

  const contextValue = {
    setInvoiceFiscalCode,
    getInvoiceFiscalCode,
    isValidInvoiceFiscalCode,
    setInvoiceName,
    getInvoiceName,
    isValidInvoiceFirstName,
    setInvoiceLastName,
    getInvoiceLastName,
    isValidInvoiceLastName,
    setInvoiceState,
    getInvoiceState,
    setInvoiceCountry,
    getInvoiceCountry,
    setInvoiceCity,
    getInvoiceCity,
    isValidInvoiceCity,
    setInvoiceZip,
    getInvoiceZip,
    isValidInvoiceZip,
    setInvoiceAddress,
    getInvoiceAddress,
    isValidInvoiceAddress,
    isValidInvoiceData,
  };

  return (
    <InvoiceContext.Provider value={contextValue}>
      {props.children}
    </InvoiceContext.Provider>
  );
};