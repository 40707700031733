import styled from "styled-components";

export const SOrderContainer = styled.div`
  margin: 20px 0px 50px 0px;
  border: 1px solid ${({ theme }) => theme.tableBgHead};
  border-radius: 8px;
  padding: 0px;
  width: 100%;
`;

export const SOrderHerder = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;

  border-bottom: 1px solid ${({ theme }) => theme.tableBgHead};
  padding: 15px 30px;
  width: 100%;

  background-color: rgba(94, 94, 94, 0.1);

  @media (max-width: 700px) {
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    justify-items: inherit;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 700px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const SOrderBody = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 2fr 1fr;

  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;

    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;

    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const SColProducts = styled.div`
`;

export const SColRecap = styled.div`
  border-left: 1px solid grey;
  padding-left: 15px;

  @media (max-width: 900px) {
    border-top: 1px solid grey;
    border-left: none !important;
    padding-top: 15px;
    padding-left: 0px;
  }
`;

export const SProduct = styled.div`
  width: 100%;
  padding: 15px 0px;
`;