import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import { MessageContext } from "../../../services/context/message";
import { UserContext } from "../../../services/context/User";
import useAxiosPrivate from '../../../features/hooks/useAxiosPrivate';

import { BannerError } from "../../../components/banner";

import { SContainerForm, SGridContainerTwo } from './styles';
import { SDivider20 } from '../../../styles/dividerStyles';
import { SBtnPrimary } from '../../../styles/buttonStyles';

export default function FormPassword() {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const { setErrMsg, setInfoMsg } = useContext(MessageContext);
  const { setUserPassword, getUserPassword, setUserOldPassword, getUserOldPassword, isValidUserPassword, setUserMatchPassword, isPasswordsMatch } = useContext(UserContext);

  const handleSubmitPassword = async(e) => {
    e.preventDefault();
    try {
      if (!isValidUserPassword() || !isPasswordsMatch()) {
        setErrMsg(t('err-server-different_passwords')); // l'errore compilare correttamente i campi
        return;
      }

      const response = await axiosPrivate.post(`/update-password`, {
        oldPassword: getUserOldPassword(),
        newPassword: getUserPassword(),
      });
      if (response.status === 200) {
        setInfoMsg(t('form_user-info-update_password'));
      } else {
        setErrMsg(t('err-server-old_pass'));
      }
    } catch (err) {
      if(!err?.response){
        setErrMsg(t('err-server-no_response'));
      } else if (err.response?.status === 400) {
        setErrMsg(t('err-server-bad_fill'));
      }
    } finally {
      setBtnDisabled(false);
    }
  }

  return (
    <form onSubmit={handleSubmitPassword} autoComplete="none">
      <SContainerForm>
        <h5>{t('form_new_password-h1')}</h5>
        <SDivider20/>

        <label htmlFor='oldPassword'>{t('form_new_password-old_password')}</label>
        <input
          type="password"
          id="oldPassword"
          onChange={(e) => setUserOldPassword(e.target.value)}
          placeholder={t('form_new_password-old_password')}
          maxLength="32"
          autoComplete="new-password"
          required
        />

        <SDivider20/>

        <SGridContainerTwo>
          <div>
            <label htmlFor='password'>{t('form_new_password-new_password')}</label>
            <input
              type="password"
              id="password"
              onChange={(e) => setUserPassword(e.target.value)}
              placeholder={t('form_new_password-new_password')}
              maxLength="32"
              autoComplete="new-password"
              required
              aria-invalid={isValidUserPassword() ? "false" : "true"}
              aria-describedby="passwordnote"
            />
            { getUserPassword() && !isValidUserPassword() &&
              <BannerError id="passwordnote">
                <p>{t('form_new_password-error-new_password')}</p>
              </BannerError>
            }
          </div>

          <div>
            <label htmlFor='repassword'>{t('form_new_password-new_repassword')}</label>
            <input
              type="password"
              id="repassword"
              onChange={(e) => setUserMatchPassword(e.target.value)}
              placeholder={t('form_new_password-new_repassword')}
              maxLength="32"
              autoComplete="new-password"
              required
              aria-invalid={isPasswordsMatch() ? "false" : "true"}
              aria-describedby="matchpasswordnote"
            />
            { !isPasswordsMatch() &&
              <BannerError id="matchpasswordnote">
                <p>{t('form_new_password-error-new_repassword')}</p>
              </BannerError>
            }
          </div>
        </SGridContainerTwo>

        <SDivider20/>
        <div className="text-center">
          <SBtnPrimary disabled={btnDisabled}>{t('account_data-update_password')}</SBtnPrimary>
        </div>
      </SContainerForm>
    </form>
  );
}