import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { InvoiceContext } from "../../../services/context/Invoice";

import { SContainerForm, SGridContainer } from '../../account/data/styles';
import { SDivider10, SDivider20 } from '../../../styles/dividerStyles';

import STATEOPTIONS from "../../../config/stateList";
import COUNTRYUSOPTIONS from "../../../config/countryUSList";

export default function FormInvoice() {
  const { t } = useTranslation();
  const {
    getInvoiceFiscalCode,
    getInvoiceName,
    getInvoiceLastName,
    getInvoiceCity,
    getInvoiceZip,
    getInvoiceAddress
  } = useContext(InvoiceContext);

  return (
    <SContainerForm>
      <h5>{t('form_invoice-h1')}</h5>
      <SDivider20/>

      <SGridContainer>
        <div>
          <label htmlFor='fiscalCode'>{t('form_invoice-cf')}</label>
          <input type="text"
            id="fiscalCode"
            value={getInvoiceFiscalCode()}
            disabled={true}
          />
        </div>

        <div>
          <label htmlFor='firstName'>{t('form_invoice-name')}</label>
          <input type="text"
            id="firstName"
            value={getInvoiceName()}
            disabled={true}
          />
        </div>

        <div>
          <label htmlFor='lastName'>{t('form_invoice-last_name')}</label>
          <input type="text"
            id="lastName"
            value={getInvoiceLastName()}
            disabled={true}
          />
        </div>

        <InputState/>

        <InputCountry/>

        <div>
          <label htmlFor="city">{t('form_invoice-city')}</label>
          <input type="text"
            id="city"
            value={getInvoiceCity()}
            disabled={true}
          />
        </div>

        <div>
          <label htmlFor='zip'>ZIP / CAP</label>
          <input type="text"
            id="zip"
            value={getInvoiceZip()}
            disabled={true}
          />
        </div>

        <div>
          <label htmlFor='address'>{t('form_invoice-address')}</label>
          <input type="text"
            id="address"
            value={getInvoiceAddress()}
            disabled={true}
          />
        </div>
      </SGridContainer>

      <SDivider10/>
    </SContainerForm>
  );
}

const InputState = () => {
  const { t } = useTranslation();
  const { getInvoiceState } = useContext(InvoiceContext);
  const [state, setState] = useState("");

  useEffect(() => {
    if (getInvoiceState()){
      const checkState = obj => obj.value === getInvoiceState();
      setState(STATEOPTIONS.find(checkState).label);
    }
  }, [getInvoiceState])

  return (
    <div>
      <label htmlFor='state'>{t('form_invoice-state')}</label>
      <input type="text"
        id="state"
        value={state}
        disabled={true}
      />
    </div>
  );
}

const InputCountry = () => {
  const { t } = useTranslation();
  const { getInvoiceCountry } = useContext(InvoiceContext);
  const [country, setCountry] = useState("");

  useEffect(() => {
    if (getInvoiceCountry()){
      const checkCountry = obj => obj.value === getInvoiceCountry();
      setCountry(COUNTRYUSOPTIONS.find(checkCountry).label);
    }
  }, [getInvoiceCountry])

  return (
    <div>
      <label htmlFor='country'>{t('form_invoice-country')}</label>
      <input type="text"
        id="country"
        value={country}
        disabled={true}
      />
    </div>
  );
}