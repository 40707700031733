import { createContext, useState } from "react";

import { isValidEmail, isValidPassword } from '../../utils/regex';

export const UserContext = createContext(null);

export const UserContextProvider = (props) => {
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const [matchPassword, setMatchPassword] = useState('');
  const [discountCode, setDiscountCode] = useState('');

  const setUserEmail = (email) => {
    setEmail(email);
    isValidEmail(email) ? setValidEmail(true) : setValidEmail(false);
  }
  const getUserEmail = () => {
    return email;
  }
  const isValidUserEmail = () => {
    return validEmail;
  }

  const setUserOldPassword = (password) => {
    setOldPassword(password);
  }
  const getUserOldPassword = () => {
    return oldPassword;
  }

  const setUserPassword = (password) => {
    setPassword(password);
    isValidPassword(password) ? setValidPassword(true) : setValidPassword(false);
  }
  const getUserPassword = () => {
    return password;
  }
  const isValidUserPassword = () => {
    return validPassword;
  }
  const setUserMatchPassword = (matchPassword) => {
    setMatchPassword(matchPassword);
  }
  const isPasswordsMatch = () => {
    return password === matchPassword ? true : false;
  }

  const setUserDiscountCode = (discountCode) => {
    setDiscountCode(discountCode);
  }
  const getUserDiscountCode = () => {
    return discountCode;
  }

  const isValidUserData = () => {
    return validEmail && validPassword && isPasswordsMatch() ? true : false;
  }

  const contextValue = {
    setUserEmail,
    getUserEmail,
    isValidUserEmail,
    setUserOldPassword,
    getUserOldPassword,
    setUserPassword,
    getUserPassword,
    isValidUserPassword,
    setUserMatchPassword,
    isPasswordsMatch,
    isValidUserData,
    setUserDiscountCode,
    getUserDiscountCode
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};