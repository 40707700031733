import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import { apiRequestResetPsw } from '../../../services/api/account';
import Head from '../../../layouts/head';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { BannerError, BannerInfo } from '../../../components/banner';
import { SBtnPrimary } from '../../../styles/buttonStyles';
import { SDivider10, SDivider30, SDivider100 } from '../../../styles/dividerStyles';

export default function AccountRecovery() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('account_recovery-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
      />
      <Header/>
      <SDivider30/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  return(
    <main className='container'>
      <Intro/>
      <FormContent/>
    </main>
  );
}

const Intro = () => {
  const { t } = useTranslation();

  return (
    <div className='text-center'>
      <h1>{t('account_recovery-h1')}</h1>
      <SDivider30/>

      <p>{t('account_recovery-main_1')}</p>
    </div>
  )
}

const FormContent = () => {
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useState('');
  const [infoMsg, setInfoMsg] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      setErrMsg("");
      setInfoMsg("");
      setBtnDisabled(true);

      const response = await apiRequestResetPsw({ email: email });
      if (response.status === 200) {
        setEmail("");
        setInfoMsg(t('account_recovery-h1'));
      }
    } catch (err) {
      if (!err?.response){
        setErrMsg(t('err-server-no_response'));
      }
    } finally {
      setBtnDisabled(false);
    }
  }

  return (
    <div className='container-sm'>
      <SDivider30/>
      <BannerError aria-live="assertive">{errMsg}</BannerError>

      <form onSubmit={handleSubmit} autoComplete="none">
        <label htmlFor='email'>{t('form_user-email')}</label>
        <input
          type="email"
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t('form_user-email')}
          maxLength="64"
          autoComplete="off"
          required
        />

        <SDivider10/>
        <div className="text-center">
          <SBtnPrimary className='width-100' disabled={btnDisabled}>
            {t('account_recovery-btn')}
          </SBtnPrimary>
        </div>
      </form>

      <BannerInfo aria-live="assertive">{infoMsg}</BannerInfo>
    </div>
  )
}