import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { apiActiveAccount } from '../../../services/api/account';
import Head from '../../../layouts/head';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { BannerError } from '../../../components/banner';
import { SDivider30, SDivider100 } from '../../../styles/dividerStyles';

export default function ActiveAccount() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('active_account-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
      />
      <Header/>
      <SDivider30/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  const { t } = useTranslation();
  const { code } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [active, setActive] = useState(false);
  const [errMsg, setErrMsg] = useState();

  useEffect(() => {
    const activeAccount = async () => {
      try {
        setErrMsg("");
        const response = await apiActiveAccount(code);
        if (response.status === 200) {
          setActive(true);
        }
        setLoaded(true);
      } catch (err) {
        if (!err?.response) {
          setErrMsg("No Server Response");
        }
      }
    }

    activeAccount();

    // eslint-disable-next-line
  }, []);

  return(
    <main className='container-sm text-center'>
      <h1>{t('active_account-h1')}</h1>
      <SDivider30/>

      <BannerError>{errMsg}</BannerError>

      { loaded &&
        active ?
        <p>
          {t('active_account-main_1')}
          <br/>
          <Link to={t('url-login')}>{t('active_account-main_2')}</Link>
        </p>
        :
        <p>
          {t('active_account-main_negative')}
        </p>
      }
    </main>
  );
}