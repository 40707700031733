export const formatCurrency = (value) => {
  const num = Number(value);
  return num.toFixed(2);
};

export const vatCalculation = (amount, vat) => {
  return amount * (vat / 100)
}

export const calculateVAT = (product) => {
  const amount = product.price * product.quantity;
  return Number(amount * (product.vat / 100));
}

export const calculateOrderTaxable = (products) => {
  let result = 0;
  for (const product of products) {
    result += product.price * product.quantity;
  }
  return Number(result);
}

export const calculateOrderVat = (products) => {
  let result = 0;
  for (const product of products) {
    result += calculateVAT(product);
  }
  return Number(result);
}

export const calculateOrderTotal = (products) => {
  let result = 0;
  for (const product of products) {
    result += (product.price * product.quantity) + calculateVAT(product);
  }
  return Number(result);
}