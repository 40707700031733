import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { ShippingContext } from "../../services/context/Shipping";
import { SErrorBanner } from "../../components/banner/styles";
import { SGridContainer } from '../account/data/styles';
import { SDivider10, SDivider20 } from '../../styles/dividerStyles';
import SelectComponent from "../../components/select";

import STATEOPTIONS from "../../config/stateList";
import COUNTRYUSOPTIONS from "../../config/countryUSList";
import COUNTRYITOPTIONS from "../../config/countryITList";

export default function FormShipping() {
  const { t } = useTranslation();
  const {
    setShippingState,
    getShippingState,
    setShippingCountry,
    setShippingCity,
    getShippingCity,
    isValidShippingCity,
    setShippingZip,
    getShippingZip,
    isValidShippingZip,
    setShippingAddress,
    getShippingAddress,
    isValidShippingAddress,
    setShippingNote
  } = useContext(ShippingContext);

  const changeState = (state) => {
    setShippingState(state);
  }

  const changeCountry = (country) => {
    setShippingCountry(country);
  }

  return (
    <>
      <h4>{t('form_shipping-h1')}</h4>
      <SDivider20/>

      <SGridContainer>
        <SelectComponent
          label={t('form_invoice-state')}
          options={STATEOPTIONS}
          onChange={changeState}
          required={true}
        />

        <SelectCountry
          state={getShippingState}
          changeCountry={changeCountry}
          required={true}
        />

        <div>
          <label htmlFor='shippingZip'>ZIP / CAP</label>
          <input
            type="text"
            id="shippingZip"
            onChange={(e) => setShippingZip(e.target.value)}
            placeholder="ZIP / CAP"
            maxLength="8"
            required
            aria-invalid={getShippingZip() ? "false" : "true"}
            aria-describedby="ZipNote"
          />
          { getShippingZip() && !isValidShippingZip() &&
            <SErrorBanner id="ZipNote">
              <p>{t('form_invoice-error-zip')}</p>
            </SErrorBanner>
          }
        </div>

        <div>
          <label htmlFor='shippingCity'>{t('form_shipping-city')}</label>
          <input
            type="text"
            id="shippingCity"
            onChange={(e) => setShippingCity(e.target.value)}
            placeholder={t('form_shipping-city')}
            maxLength="32"
            required
            aria-invalid={getShippingCity() ? "false" : "true"}
            aria-describedby="CityNote"
          />
          { getShippingCity() && !isValidShippingCity() &&
            <SErrorBanner id="CityNote">
              <p>{t('form_invoice-error-city')}</p>
            </SErrorBanner>
          }
        </div>

        <div>
          <label htmlFor='shippingAddress'>{t('form_shipping-address')}</label>
          <input
            type="text"
            id="shippingAddress"
            onChange={(e) => setShippingAddress(e.target.value)}
            placeholder={t('form_shipping-address')}
            maxLength="64"
            required
            aria-invalid={getShippingAddress() ? "false" : "true"}
            aria-describedby="AddressNote"
          />
          { getShippingAddress() && !isValidShippingAddress() &&
            <SErrorBanner id="AddressNote">
              <p>{t('form_invoice-error-address')}</p>
            </SErrorBanner>
          }
        </div>
      </SGridContainer>

      <SDivider20/>

      <div>
        <label htmlFor='note'>{t('form_shipping-note')}</label>
        <textarea
          id="note"
          onChange={(e) => setShippingNote(e.target.value)}
          placeholder={t('form_shipping-note')}
          maxLength="500"
          rows="4"
        />
      </div>

      <SDivider10/>
    </>
  );
}

const SelectCountry = ({ state, changeCountry, required = false }) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState();
  const [options, setOptions] = useState();

  useEffect(() => {
    switch (state()) {
      case 'US':
        setOptions(COUNTRYUSOPTIONS);
      break;
      case 'IT':
        setOptions(COUNTRYITOPTIONS)
      break;
      default:
        setOptions(COUNTRYITOPTIONS);
      break;
    }
  }, [state])

  useEffect(() => {
    changeCountry(country)

    // eslint-disable-next-line
  }, [country])

  const onChange = (value) => {
    setCountry(value);
  }

  return (
    options &&
    <SelectComponent
      label={t('form_invoice-country')}
      options={options}
      onChange={onChange}
      required={required}
    />
  );
}