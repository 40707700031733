import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import { MessageContext } from "../../../services/context/message";
import { UserContext } from "../../../services/context/User";
import useAxiosPrivate from '../../../features/hooks/useAxiosPrivate';

import { SContainerForm, SGridContainerTwo } from './styles';
import { SDivider20 } from '../../../styles/dividerStyles';
import { SBtnPrimary } from '../../../styles/buttonStyles';

export default function FormDiscountCode() {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const { setErrMsg, setInfoMsg } = useContext(MessageContext);
  const { getUserDiscountCode, setUserDiscountCode } = useContext(UserContext);

  const handleSubmitAccount = async(e) => {
    e.preventDefault();
    try {
      setBtnDisabled(true);

      const response = await axiosPrivate.post(`/update-account`, { discountCode: getUserDiscountCode() });
      if (response.status === 200) {
        setInfoMsg(t('form_user-info-update'))
      }
    } catch (err) {
      if (!err?.response){
        setErrMsg(t('err-server-no_response'));
      } else if (err.response?.status === 400) {
        setErrMsg(t('err-server-bad_fill'));
      } else if (err.response?.status === 404) {
        setErrMsg(t('account_data-discount-invalid_code'));
      }
    } finally {
      setBtnDisabled(false);
    }
  }

  return (
    <form onSubmit={handleSubmitAccount} autoComplete="none">
      <SContainerForm>
        <h5>{t('account_data-discount-h5')}</h5>
        <SDivider20/>

        <SGridContainerTwo>
          <div>
            <label htmlFor='discount'>{t('account_data-discount-input')}</label>
            <input type="text"
              id="discount"
              value={getUserDiscountCode()}
              onChange={(e) => setUserDiscountCode(e.target.value)}
              maxLength="15"
              autoComplete="off"
              required
            />
          </div>
        </SGridContainerTwo>

        <SDivider20/>
        <div className="text-center">
          <SBtnPrimary disabled={btnDisabled}>{t('account_data-update')}</SBtnPrimary>
        </div>
      </SContainerForm>
    </form>
  );
}