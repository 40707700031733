import { createContext, useState } from "react";

import { vatCalculation } from '../../utils/string';

export const CartContext = createContext(null);

export const CartContextProvider = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState("");

  const addToCart = (newItem) => {
    const isInTheCart = (newItem) => {
      for (const i in cartItems) {
        if (cartItems[i].id === newItem.id) {
          return true;
        }
      }
      return false;
    }

    const addItemToCart = (newItem) => {
      setCartItems([...cartItems, {
        id: newItem.id,
        name: newItem.name,
        quantity: 1,
        price: newItem.price,
        vat: newItem.vat
      }]);

      return true;
    }

    return isInTheCart(newItem) ? false : addItemToCart(newItem);
  };

  const getCart = () => {
    return cartItems;
  }

  const removeFromCart = (itemId) => {
    setCartItems(cartItems.filter((item) => item.id !== itemId));
  }

  const updateQuantity = (itemId, newAmount) => {
    setCartItems(current =>
      current.map(item => {
        if (item.id === itemId) {
          return {...item, quantity: newAmount};
        }
        return item;
      }),
    );
  };

  const totalTaxable = () => {
    let totalAmount = 0;
    for (const i in cartItems) {
      totalAmount += cartItems[i].price * cartItems[i].quantity
    }
    return totalAmount;
  }

  const totalVAT = () => {
    let totalAmount = 0;
    for (const i in cartItems) {
      totalAmount += (vatCalculation(cartItems[i].price, cartItems[i].vat)) * cartItems[i].quantity
    }
    return totalAmount;
  }

  const totalDiscount = () => {
    return ( (Number(totalTaxable()) + Number(totalVAT())) * (discount / 100) );
  }

  const totalAmount = () => {
    return totalTaxable() + totalVAT() - totalDiscount();
  };

  const isEmptyCart = () => {
    return getCart()?.length !== 0 ? false : true;
  }

  const setCartDiscountCode = (discountCode) => {
    setDiscountCode(discountCode);
  }
  const getCartDiscountCode = () => {
    return discountCode;
  }

  const setCartDiscount = (discount) => {
    setDiscount(discount);
  }
  const getCartDiscount = () => {
    return discount;
  }

  const isValidDiscountCode = () => {
    return discount && discountCode ? true : false;
  }

  const contextValue = {
    addToCart,
    getCart,
    removeFromCart,
    updateQuantity,
    totalTaxable,
    totalVAT,
    totalDiscount,
    totalAmount,
    isEmptyCart,
    setCartDiscountCode,
    getCartDiscountCode,
    setCartDiscount,
    getCartDiscount,
    isValidDiscountCode
  };

  return (
    <CartContext.Provider value={contextValue}>
      {props.children}
    </CartContext.Provider>
  );
};