import React from 'react';
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from "react-router-dom";

import { SFooter, Sh3, Sh5, Sh6, Sul, Sli, SUnder } from "./styles"
import { SDivider10, SDivider20, SDivider40 } from '../../styles/dividerStyles';
import logo from '../../assets/img/logo.png';
import { GrFacebook, GrInstagram } from "react-icons/gr";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <SFooter>
      <SDivider20/>
      <div className="container">
        <div className="row">

          <div className="col-lg-4 col-md-12 col-sm-12">
            <SDivider20/>
            <div className='text-center'>
              <Sh3>{process.env.REACT_APP_APP_NAME}</Sh3>
              <img src={logo} height="220" alt="logo"/>

              <nav>
                <Sul>
                  <BrowserView>
                    <Sli><GrFacebook/> <a href={process.env.REACT_APP_FACEBOOK} target="_blank" rel="noopener noreferrer">Facebook</a></Sli>
                    <Sli><GrInstagram/> <a href={process.env.REACT_APP_INSTAGRAM} target="_blank" rel="noopener noreferrer">Instagram</a></Sli>
                  </BrowserView>
                  <MobileView>
                    <Sli><GrFacebook/> <a href={process.env.REACT_APP_FACEBOOK_ON_MOBILE} target="_blank" rel="noopener noreferrer">Facebook</a></Sli>
                    <Sli><GrInstagram/> <a href={process.env.REACT_APP_INSTAGRAM_ON_MOBILE} target="_blank" rel="noopener noreferrer">Instagram</a></Sli>
                  </MobileView>
                </Sul>
              </nav>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className='text-center'>
              <SDivider20/>
              <Sh5>{t('footer-1')}</Sh5>
              <SDivider10/>

              <Sh6>{t('footer-2')}</Sh6>
              <SDivider10/>
              <a className='m-l-15' href={process.env.REACT_APP_GOOGLE_MAPS} target="_blank" rel="noopener noreferrer">
                {process.env.REACT_APP_CITY_ADDRESS}, {process.env.REACT_APP_ZIP} {process.env.REACT_APP_CITY} ({process.env.REACT_APP_STATE})
              </a>

              <SDivider20/>

              <Sh6>{t('footer-3')}</Sh6>
              <SDivider10/>
              <a className='m-l-15' href={`tel:` + process.env.REACT_APP_CONTACT_PHONE}>{process.env.REACT_APP_CONTACT_PHONE}</a>

              <SDivider20/>

              <Sh6>{t('footer-4')}</Sh6>
              <SDivider10/>
              <a className='m-l-15' href={`mailto:` + process.env.REACT_APP_CONTACT_EMAIL}>{process.env.REACT_APP_CONTACT_EMAIL}</a>

              <SDivider20/>

              <Sh6>{t('footer-5')}</Sh6>
              <SDivider10/>
              <div className="m-l-15">{process.env.REACT_APP_PIVA}</div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className='text-center'>
              <SDivider20/>

              <Sh5>{t('footer-6')}</Sh5>
              <nav>
                <Sul className="m-top-10">
                  <Sli>
                    <Link to={t('url-olive_oil')}>{t('footer-link-olive_oil')}</Link>
                  </Sli>
                  <Sli>
                    <Link to={t('url-tropic_fruit')}>{t('footer-link-tropic_fruit')}</Link>
                  </Sli>
                  <Sli>
                    <Link to={t('url-market')}>{t('footer-link-market')}</Link>
                  </Sli>
                  <Sli>
                    <Link to={t('url-cart')}>{t('footer-link-cart')}</Link>
                  </Sli>
                  <Sli>
                    <Link to={t('url-login')}>{t('footer-link-login')}</Link>
                  </Sli>
                  <Sli>
                    <Link to={t('url-contact')}>{t('footer-link-contact')}</Link>
                  </Sli>
                  <Sli>
                    <Link to={t('url-privacy')}>{t('footer-link-policy')}</Link>
                  </Sli>
                </Sul>
              </nav>
            </div>
          </div>

        </div>
      </div>

      <SDivider40/>

      <SUnder>
        © 2023 All Rights Reserved
      </SUnder>
    </SFooter>
  );

}