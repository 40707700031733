import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { OrderContextProvider, OrderContext } from '../../../services/context/Order';
import useAxiosPrivate from '../../../features/hooks/useAxiosPrivate';
import { formatDay } from '../../../utils/date';
import { formatCurrency } from '../../../utils/string';

import Head from '../../../layouts/head';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { BannerError } from '../../../components/banner';
import { PaginationStep } from '../../../components/pagination';

import {
  SOrderContainer,
  SOrderHerder,
  SOrderBody,
  SProduct,
  SColProducts,
  SColRecap,
} from './styles';
import { SDivider10, SDivider30, SDivider50, SDivider100 } from '../../../styles/dividerStyles';

export default function AccountOrder() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('orders-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        path='orders'
      />
      <Header/>
      <SDivider50/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  const { t } = useTranslation();

  return(
    <main className='container'>
      <h2>{t('orders-h1')}</h2>
      <SDivider30/>

      <Orders/>
    </main>
  );
}

const Orders = () => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [errMsg, setErrMsg] = useState();
  const [orders, setOrders] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const getOrders = async () => {
      try {
        setErrMsg("");

        const response = await axiosPrivate.get(`/orders?offset=${offset}`);
        if (response.data.length === 0 && currentPage !== 1) {
          handlePageChange(currentPage - 1);
        } else {
          setOrders(response.data);
        }
      } catch (err) {
        if (!err?.response) {
          setErrMsg("No Server Response");
        }
      }
    }

    getOrders();

    // eslint-disable-next-line
  }, [offset]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
    setOffset((selectedPage * itemsPerPage) - itemsPerPage);
  }

  return (
    <>
      <BannerError>{errMsg}</BannerError>

      { orders?.length ? orders.map((order, i) =>
        <OrderContextProvider key={i}>
          <Order order={order}/>
        </OrderContextProvider>
      ) :
        <>
        <strong>{t('orders-no_order')}</strong>
          <SDivider10/>
          <Link to={t('url-market')}>{t('orders-start')}</Link>
        </>
      }

      { orders?.length > 14 &&
        <PaginationStep
          currentPage={currentPage}
          onChange={handlePageChange}
        />
      }
    </>
  );
}

const Order = ({ order }) => {
  const { t } = useTranslation();
  const {
    setOrder,
    totalTaxable,
    totalVAT,
    totalDiscount,
    totalAmount
  } = useContext(OrderContext);

  useEffect(() => {
    setOrder(order);

    // eslint-disable-next-line
  }, [order])

  return (
    <SOrderContainer>
      <SOrderHerder>
        <div>
          {t('orders-id')}: <strong>{order.id}</strong>
        </div>

        <div>
          {t('orders-data')}: <strong>{formatDay(order.date)}</strong>
        </div>

        <div>
          {t('orders-total')}: <strong>{totalAmount()} $</strong>
        </div>
      </SOrderHerder>

      <SOrderBody>
        <SColProducts>
          { order.products.map((product, i) =>
            <Product key={i} product={product}/>
          )}
        </SColProducts>

        <SColRecap>
          <p>
            {t('orders-taxable')}: <strong>$ {formatCurrency(totalTaxable())}</strong>
          </p>
          <p>
            {t('orders-total_vat')}: <strong>$ {formatCurrency(totalVAT())}</strong>
          </p>
          { order.discount &&
            <p>
              {t('orders-discount')}: <strong>$ {formatCurrency(totalDiscount())}</strong>
            </p>
          }
          <p>
          { order.tracking ?
            <>{t('orders-tracking')}: <strong>{order.tracking}</strong></>
            :
            <>{t('orders-no_shipped')}</>
          }
          </p>
        </SColRecap>
      </SOrderBody>
    </SOrderContainer>
  )
}

const Product = ({ product }) => {
  const { t } = useTranslation();

  return (
    <SProduct>
      <strong>{product.name}</strong> x {product.quantity}
      <SDivider10/>

      {t('orders-price_unit')}: {formatCurrency(product.price)} $
    </SProduct>
  )
}