import { createContext, useState } from "react";

export const OrderContext = createContext(null);

export const OrderContextProvider = (props) => {
  const [invoice, setInvoice] = useState();
  const [products, setProducts] = useState([]);
  const [discount, setDiscount] = useState();

  const setOrder = (order) => {
    setInvoice(order);
    setProducts(order.products);
    setDiscount(order.discount);
  }
  const getOrder = () => {
    return invoice;
  }

  const totalTaxable = () => {
    let result = 0;
    for (const i in products) {
      result += Number(products[i].price) * Number(products[i].quantity)
    }
    return Number(result);
  }

  const totalVAT = () => {
    let result = 0;
    for (const i in products) {
      result += Number(products[i].price) * percentToDecimal(products[i].vat) * Number(products[i].quantity)
    }
    return Number(result);
  }

  const totalDiscount = () => {
    return !discount ? 0 : (Number(totalTaxable()) + Number(totalVAT())) * percentToDecimal(discount);
  }

  const totalAmount = () => {
    return (totalTaxable() + totalVAT() - totalDiscount()).toFixed(2);
  };

  const percentToDecimal = (percent) => {
    return (percent / 100);
  }

  const contextValue = {
    setOrder,
    getOrder,
    totalTaxable,
    totalVAT,
    totalDiscount,
    totalAmount
  };

  return (
    <OrderContext.Provider value={contextValue}>
      {props.children}
    </OrderContext.Provider>
  );
};