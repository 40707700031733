import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { apiResetPsw } from '../../../services/api/account';
import { UserContextProvider, UserContext } from "../../../services/context/User";
import Head from '../../../layouts/head';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { BannerError, BannerInfo } from '../../../components/banner';
import { SBtnPrimary } from '../../../styles/buttonStyles';
import { SDivider10, SDivider30, SDivider100 } from '../../../styles/dividerStyles';

export default function ResetPassword() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('account_reset_password-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
      />
      <Header/>
      <SDivider30/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  return(
    <main className='container'>
      <Intro/>
      <UserContextProvider>
        <FormContent/>
      </UserContextProvider>
    </main>
  );
}

const Intro = () => {
  const { t } = useTranslation();

  return (
    <div className='text-center'>
      <h1>{t('account_reset_password-h1')}</h1>
      <SDivider30/>

      <p>{t('account_reset_password-main_1')}</p>
    </div>
  )
}

const FormContent = () => {
  const { t } = useTranslation();
  const { code } = useParams();
  const [errMsg, setErrMsg] = useState('');
  const [infoMsg, setInfoMsg] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const {
    setUserPassword,
    getUserPassword,
    isValidUserPassword,
    setUserMatchPassword,
    isPasswordsMatch
  } = useContext(UserContext);

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      setErrMsg("");
      setInfoMsg("");
      setBtnDisabled(true);

      if (!isValidUserPassword() || !isPasswordsMatch()) {
        setErrMsg(t('account_reset_password-err-input'));
        return;
      }

      const response = await apiResetPsw({
        code: code,
        password: getUserPassword()
      });
      if (response.status === 200) {
        setUserPassword("");
        setUserMatchPassword("");
        setInfoMsg(t('account_reset_password-info-1'));
      }
    } catch (err) {
      if (!err?.response){
        setErrMsg(t('err-server-no_response'));
      }
    } finally {
      setBtnDisabled(false);
    }
  }

  return (
    <div className='container-sm'>
      <SDivider30/>
      <BannerError aria-live="assertive">{errMsg}</BannerError>

      <form onSubmit={handleSubmit} autoComplete="none">
        <div>
          <label htmlFor='password'>{t('account_reset_password-input_psw')}</label>
          <input
            type="password"
            id="password"
            onChange={(e) => setUserPassword(e.target.value)}
              placeholder="*****"
              maxLength="32"
              autoComplete="new-password"
              required
              aria-invalid={isValidUserPassword() ? "false" : "true"}
              aria-describedby="passwordnote"
            />
          { getUserPassword() && !isValidUserPassword() &&
            <BannerError id="passwordnote">
              <p>{t('form_user-error-password')}</p>
            </BannerError>
          }
        </div>

        <SDivider10/>

        <div>
          <label htmlFor='repassword'>{t('account_reset_password-input_re_psw')}</label>
          <input
            type="password"
            id="repassword"
            onChange={(e) => setUserMatchPassword(e.target.value)}
              placeholder="*****"
              maxLength="32"
              autoComplete="new-password"
              required
              aria-invalid={isPasswordsMatch() ? "false" : "true"}
              aria-describedby="matchpasswordnote"
            />
          { !isPasswordsMatch() &&
            <BannerError id="matchpasswordnote">
              <p>{t('form_user-error-repassword')}</p>
            </BannerError>
          }
        </div>

        <SDivider10/>
        <div className="text-center">
          <SBtnPrimary className='width-100' disabled={btnDisabled}>
            {t('account_reset_password-btn')}
          </SBtnPrimary>
        </div>
      </form>

      <BannerInfo aria-live="assertive">{infoMsg}</BannerInfo>
    </div>
  )
}