import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ROLES from '../../config/rolesList';
import useAuth from '../../features/hooks/useAuth';
import useLogout from '../../features/hooks/useLogout';
import {
  SHeaderBar,
  SAreaLogo,
  SLogo,
  SLogoImg,
  SNavBar,
  SNavMenuLi,
  SNavMenuA,
  SFaBar,
  /* SMenuLang,
  SMenuLangUl,
  SMenuLangButton,
  SMenuLangSpan, */
  SMenuAccount,
  SMenuAccountButton,
  SNavDropdown,
  SNavNotDropdown
} from "./styles";

import logo from '../../assets/img/logo-light.png';
/* import { BsGlobe } from 'react-icons/bs'; */
import { FaBars } from "react-icons/fa";

/* const languages = [
  {
    code: 'en-US',
    name: 'United States',
    flag: "/assets/img/flag/us.svg"
  },
  {
    code: 'it',
    name: 'Italiano',
    flag: "/assets/img/flag/it.svg"
  },
] */

export default function Header() {
  const { t } = useTranslation();
  const [ menuActive, setMenuActive ] = useState(false);

  return (
    <SHeaderBar>
      <SAreaLogo>
        <SLogo to={t('url-home')}>
          <SLogoImg src={logo} alt="logo"/>
        </SLogo>
        {/* <MenuLang/> */}
      </SAreaLogo>

      <SNavBar $isopen={menuActive}>
        <NavbarMenu/>
      </SNavBar>

      <SFaBar onClick={() => setMenuActive(!menuActive)}>
        <FaBars/>
      </SFaBar>
    </SHeaderBar>
  );
}

const NavbarMenu = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();

  return (
    <>
      <SNavMenuLi>
        <SNavMenuA to={t('url-olive_oil')}>{t('header-olive_oil')}</SNavMenuA>
      </SNavMenuLi>
      <SNavMenuLi>
        <SNavMenuA to={t('url-tropic_fruit')}>{t('header-tropic_fruit')}</SNavMenuA>
      </SNavMenuLi>
      <SNavMenuLi>
        <SNavMenuA to={t('url-market')}>{t('header-market')}</SNavMenuA>
      </SNavMenuLi>
      <SNavMenuLi>
        <SNavMenuA to={t('url-cart')}>{t('header-cart')}</SNavMenuA>
      </SNavMenuLi>
      { auth?.roles?.find(role => [ROLES.User].includes(role)) ?
        <MenuAccount/>
        :
        <SNavMenuLi>
          <SNavMenuA to={t('url-login')}>{t('header-login')}</SNavMenuA>
        </SNavMenuLi>
      }
    </>
  );
}

function MenuAccount() {
  const { t } = useTranslation();
  const [ isOpen, setIsOpen ] = useState(false);
  const logout = useLogout();

  const signOut = async () => {
    logout();
  }

  return (
    <>
      <SNavDropdown>
        <SNavMenuLi onClick={() => setIsOpen(!isOpen)} style={{cursor: "pointer"}}>
          <SNavMenuA to="#">{t('header-account')}</SNavMenuA>
          <SMenuAccount $isOpen={isOpen}>
            <li><SMenuAccountButton to={t('url-data_account')}>{t('header-account_data')}</SMenuAccountButton></li>
            <li><SMenuAccountButton to={t('url-order')}>{t('header-account_order')}</SMenuAccountButton></li>
            <li><SMenuAccountButton to={t('url-login')} onClick={signOut}>Logout</SMenuAccountButton></li>
          </SMenuAccount>
        </SNavMenuLi>
      </SNavDropdown>

      <SNavNotDropdown>
        <SNavMenuLi>
          <SNavMenuA to={t('url-data_account')}>{t('header-account_data')}</SNavMenuA>
        </SNavMenuLi>
        <SNavMenuLi>
          <SNavMenuA to={t('url-order')}>{t('header-account_order')}</SNavMenuA>
        </SNavMenuLi>
        <SNavMenuLi>
          <SNavMenuA to={t('url-login')} onClick={signOut}>Logout</SNavMenuA>
        </SNavMenuLi>
      </SNavNotDropdown>
    </>
  );
}

/* function MenuLang() {
  const [ isOpen, setIsOpen ] = useState(false);

  function handleChangeLanguage(newCodeLang){
    window.location.assign("/" + newCodeLang + "/");
  }

  return (
    <SMenuLang onClick={() => setIsOpen(!isOpen)}>
      <BsGlobe/>
      <SMenuLangUl $isOpen={isOpen}>
        {languages.map(({ code, name, flag }) => (
          <div key={code} style={{ padding: "0px" }}>
            <SMenuLangButton onClick={() => handleChangeLanguage(code)}>
              <img src={flag} height="32" alt={name}/><SMenuLangSpan>{name}</SMenuLangSpan>
            </SMenuLangButton>
          </div>
        ))}
      </SMenuLangUl>
    </SMenuLang>
  );
} */