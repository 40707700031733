import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import { MessageContext } from "../../../services/context/message";
import { UserContext } from "../../../services/context/User";
import useAxiosPrivate from '../../../features/hooks/useAxiosPrivate';

import { BannerError } from "../../../components/banner";

import { SContainerForm, SGridContainerTwo } from '../../account/data/styles';
import { SDivider20 } from '../../../styles/dividerStyles';
import { SBtnPrimary } from '../../../styles/buttonStyles';

export default function FormUser() {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const { setErrMsg, setInfoMsg } = useContext(MessageContext);
  const { getUserEmail, setUserEmail, isValidUserEmail } = useContext(UserContext);

  const handleSubmitAccount = async(e) => {
    e.preventDefault();
    try {
      setBtnDisabled(true);
      setErrMsg("");
      setInfoMsg("");

      if (!isValidUserEmail()) {
        setErrMsg(t('err-server-bad_fill'));
        return;
      }

      const response = await axiosPrivate.post(`/update-account`, { email: getUserEmail() });
      if (response.status === 200) {
        setInfoMsg(t('form_user-info-update'))
      } else {
        setErrMsg(t('err-server-account_update'));
      }
    } catch (err) {
      if (!err?.response){
        setErrMsg(t('err-server-no_response'));
      } else if (err.response?.status === 400) {
        setErrMsg(t('err-server-bad_fill'));
      } else if (err.response?.status === 409) {
        setErrMsg(t('err-server-email_in_use'));
      } else {
        setErrMsg(t('err-server-unknow'));
      }
    } finally {
      setBtnDisabled(false);
    }
  }

  return (
    <form onSubmit={handleSubmitAccount} autoComplete="none">
      <SContainerForm>
        <h5>{t('form_user-h1')}</h5>
        <SDivider20/>

        <SGridContainerTwo>
          <div>
            <label htmlFor='email'>{t('form_user-email')}</label>
            <input
              type="email"
              id="email"
              value={getUserEmail()}
              onChange={(e) => setUserEmail(e.target.value)}
              placeholder={t('form_user-email')}
              maxLength="64"
              autoComplete="off"
              required
              aria-invalid={isValidUserEmail() ? "false" : "true"}
              aria-describedby="emailnote"
            />
            { getUserEmail() && !isValidUserEmail() &&
              <BannerError id="emailnote">
                <p>{t('form_user-error-email')}</p>
              </BannerError>
            }
          </div>
        </SGridContainerTwo>

        <SDivider20/>
        <div className="text-center">
          <SBtnPrimary disabled={btnDisabled}>{t('account_data-update')}</SBtnPrimary>
        </div>
      </SContainerForm>
    </form>
  );
}