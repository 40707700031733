import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import { CartContext } from "../../services/context/Cart";
import { formatCurrency } from '../../utils/string';

import Counter from '../../components/counter';

import { SBtnDelete } from './styles';
import { SDivider10 } from '../../styles/dividerStyles';
import { STable, STbodyTd, STheadTh } from '../../styles/tableStyles';

import { MdClose } from "react-icons/md";

export default function CartTable() {
  const { t } = useTranslation();
  const { getCart, isEmptyCart } = useContext(CartContext);

  return (
    <div>
      <h4>{t('cart-h1')}</h4>
      <SDivider10/>

      <STable>
        <thead style={{textAlign: "center"}}>
          <tr>
            <STheadTh>{t('cart-product')}</STheadTh>
            <STheadTh>{t('cart-price')}</STheadTh>
            <STheadTh>{t('cart-quantity')}</STheadTh>
            <STheadTh>{t('cart-total')}</STheadTh>
            <STheadTh></STheadTh>
          </tr>
        </thead>

        <tbody>
          { !isEmptyCart() ? getCart().map((product, i) =>
              <TableRow product={product} key={i}/>
            ) :
            <tr className='text-center'>
              <td style={{padding: "12px"}} colSpan="5">
                <strong>{t('cart-empty')}</strong>
                <SDivider10/>
                <Link to={t('url-market')}>{t('cart-start_buy')}</Link>
              </td>
            </tr>
          }
        </tbody>
      </STable>
    </div>
  )
}

const TableRow = ({ product }) => {
  const { removeFromCart, updateQuantity } = useContext(CartContext);

  const setQuantity = (newQuantity) => {
    updateQuantity(product.id, newQuantity);
  }

  return (
    <tr style={{textAlign: "center"}}>
      <STbodyTd><strong>{product.name}</strong></STbodyTd>
      <STbodyTd>{formatCurrency(product.price)} $</STbodyTd>
      <STbodyTd>
        <Counter
          max={10}
          min={1}
          value={product.quantity}
          onChange={setQuantity}
        />
      </STbodyTd>
      <STbodyTd>{formatCurrency(product.price * product.quantity)} $</STbodyTd>
      <STbodyTd>
        <SBtnDelete onClick={() => removeFromCart(product.id)} type="button"><MdClose/></SBtnDelete>
      </STbodyTd>
    </tr>
  );
}