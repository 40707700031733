import { createContext, useState } from "react";

export const MessageContext = createContext(null);

export const MessageContextProvider = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");

  const setErrMsg = (msg) => {
    setErrorMessage(msg);
  }
  const getErrMsg = () => {
    return errorMessage;
  }
  const setInfoMsg = (msg) => {
    setInfoMessage(msg);
  }
  const getInfoMsg = () => {
    return infoMessage;
  }

  const contextValue = {
    setErrMsg,
    getErrMsg,
    setInfoMsg,
    getInfoMsg,
  };

  return (
    <MessageContext.Provider value={contextValue}>
      {props.children}
    </MessageContext.Provider>
  );
};