import React, { useState, useContext } from 'react';
import { useTranslation } from "react-i18next";

import { MessageContext, MessageContextProvider } from "../../services/context/message";
import { CartContext } from "../../services/context/Cart";
import { ShippingContext, ShippingContextProvider } from '../../services/context/Shipping';
import useAxiosPrivate from '../../features/hooks/useAxiosPrivate';
import useAuth from '../../features/hooks/useAuth';
import ROLES from '../../config/rolesList';

import CartTable from './cartTable';
import FormShipping from './formShipping';
import Promo from './promo';

import Head from '../../layouts/head';
import Header from '../../components/header';
import Footer from '../../components/footer';

import { SContainer, STotalOrderRow, STotalOrderKey, STotalOrderValue } from './styles';
import { BannerError, BannerInfo } from "../../components/banner";
import { SDivider10, SDivider30, SDivider100 } from '../../styles/dividerStyles';
import { SHrLeft } from '../../styles/hrStyles';
import { SBtnPrimary } from '../../styles/buttonStyles';
import { formatCurrency } from '../../utils/string';

import { BsPhone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";

export default function Cart() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('cart-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        path='cart'
      />
      <Header/>
      <SDivider30/>
      <MessageContextProvider>
        <ShippingContextProvider>
          <Main/>
        </ShippingContextProvider>
      </MessageContextProvider>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  const { getErrMsg, getInfoMsg } = useContext(MessageContext);

  return (
    <main className='container'>
      <BannerError>{getErrMsg()}</BannerError>
      <BannerInfo>{getInfoMsg()}</BannerInfo>

      <SContainer>
        <FormCart/>

        <div>
          <OrderSummary/>
          <SDivider30/>

          <Promo/>
          <SDivider30/>

          <Helper/>
        </div>
      </SContainer>
    </main>
  );
}

const FormCart = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { setErrMsg, setInfoMsg } = useContext(MessageContext);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const { getCart, isValidDiscountCode, getCartDiscountCode, isEmptyCart } = useContext(CartContext);
  const {
    getShippingState,
    getShippingCountry,
    getShippingCity,
    getShippingZip,
    getShippingAddress,
    isValidShippingData,
  } = useContext(ShippingContext);

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      setErrMsg();
      setInfoMsg();
      setBtnDisabled(true);

      if (!isValidShippingData()) {
        setErrMsg(t('cart-err-shipping_data'));
        return;
      }
      if (isEmptyCart()) {
        setErrMsg(t('cart-err-empty'));
        return;
      }
      if (!auth?.roles?.find(role => [ROLES.User].includes(role))) {
        setInfoMsg(t('cart-info-login_require'));
        return;
      }

      const response = await axiosPrivate.post('/create-checkout', body());
      window.location.assign(response.data.url);
    } catch (err) {
      if (!err?.response) {
        setErrMsg(t('err-server-no_response'));
      } else if (err.response?.status === 400) {
        setErrMsg(t('cart-err-shipping_data'));
      } else if (err.response?.status === 422) {
        console.log(err.response.data.data)
        setErrMsg("Quanche prodotto nel carello non è più disponibile");
      } else {
        setErrMsg("Error");
      }
    } finally {
      setBtnDisabled(false);
    }

    function body() {
      let body = {
        shippingAddress: {
          state: getShippingState(),
          country: getShippingCountry(),
          zip: getShippingZip(),
          city: getShippingCity(),
          address: getShippingAddress()
        },
        products: [],
      };

      for (const product of getCart()) {
        body.products = [...body.products, {
          idProduct: product.id,
          quantity: `${product.quantity}`,
        }];
      }

      if (isValidDiscountCode()) {
        body.discountCode = getCartDiscountCode();
      }

      return body;
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <CartTable/>
      <SDivider30/>

      <FormShipping/>
      <SDivider10/>

      <SBtnPrimary type="submit" disabled={btnDisabled} style={{width: "100%"}}>
        {t('cart-buy')}
      </SBtnPrimary>
    </form>
  )
}

const OrderSummary = () => {
  const { t } = useTranslation();
  const { getCartDiscount, totalTaxable, totalVAT, totalDiscount, totalAmount } = useContext(CartContext);

  return (
    <>
      <h4>{t('cart-order_summery')}</h4>
      <SDivider10/>

      <STotalOrderRow>
        <STotalOrderKey>{t('cart-subtotal')}</STotalOrderKey>
        <STotalOrderValue>{formatCurrency(totalTaxable())} $</STotalOrderValue>
      </STotalOrderRow>

      <SDivider10/>

      <STotalOrderRow>
        <STotalOrderKey>{t('cart-taxes')}</STotalOrderKey>
        <STotalOrderValue>{formatCurrency(totalVAT())} $</STotalOrderValue>
      </STotalOrderRow>

      { getCartDiscount() &&
        <>
          <SDivider10/>

          <STotalOrderRow>
            <STotalOrderKey>{t('cart-discount')} {getCartDiscount()}%</STotalOrderKey>
            <STotalOrderValue>- {formatCurrency(totalDiscount())} $</STotalOrderValue>
          </STotalOrderRow>
        </>
      }

      <SDivider10/>
      <SHrLeft style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider10/>

      <STotalOrderRow>
        <STotalOrderKey>{t('cart-total_order')}</STotalOrderKey>
        <STotalOrderValue>{formatCurrency(totalAmount())} $</STotalOrderValue>
      </STotalOrderRow>
    </>
  );
}

const Helper = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h5>{t('cart-help')}</h5>
      <SDivider10/>

      <p>
        <BsPhone style={{fontSize: "20px", marginRight: "8px"}}/>
        <a href={`tel:` + process.env.REACT_APP_CONTACT_PHONE}>
          {process.env.REACT_APP_CONTACT_PHONE}
        </a>

        <br/><br/>

        <AiOutlineMail style={{fontSize: "20px", marginRight: "8px"}}/>
        <a href={`mailto:` + process.env.REACT_APP_CONTACT_EMAIL}>
          {process.env.REACT_APP_CONTACT_EMAIL}
        </a>
      </p>
    </div>
  );
}