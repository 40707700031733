import axios from "./axios";

export async function apiActiveAccount(code) {
  return await axios.get(`/active-account?code=${code}`,
    {
      headers: { 'Content-Type': 'application/json' }
    }
  );
}

export async function apiRequestResetPsw(body) {
  return await axios.post(`/request-reset-psw`,
    JSON.stringify(body),
    {
      headers: { 'Content-Type': 'application/json' }
    }
  );
}

export async function apiResetPsw(body) {
  return await axios.post(`/reset-psw`,
    JSON.stringify(body),
    {
      headers: { 'Content-Type': 'application/json' }
    }
  );
}

export async function apiSendEmailActivation(activation) {
  return await axios.get(`/send-activation-email/${activation}`,
    {
      headers: { 'Content-Type': 'application/json' }
    }
  );
}