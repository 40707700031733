import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { UserContext } from "../../../services/context/User";
import { BannerError } from "../../../components/banner";

import { SContainerForm, SGridContainerTwo } from '../../account/data/styles';
import { SDivider20 } from '../../../styles/dividerStyles';

export default function FormUser() {
  const { t } = useTranslation();
  const {
    getUserEmail,
    setUserEmail,
    isValidUserEmail,
  } = useContext(UserContext);

  return (
    <SContainerForm>
      <h4>{t('form_user-h1')}</h4>
      <SDivider20/>

      <SGridContainerTwo>
        <div>
          <label htmlFor='email'>{t('form_user-email')}</label>
          <input
            type="email"
            id="email"
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder={t('form_user-email')}
            maxLength="64"
            autoComplete="off"
            required
            aria-invalid={isValidUserEmail() ? "false" : "true"}
            aria-describedby="emailnote"
          />
          { getUserEmail() && !isValidUserEmail() &&
            <BannerError id="emailnote">
              <p>{t('form_user-error-email')}</p>
            </BannerError>
          }
        </div>
      </SGridContainerTwo>
    </SContainerForm>
  );
}