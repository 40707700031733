import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { MessageContextProvider, MessageContext } from "../../../services/context/message";
import { UserContextProvider, UserContext } from "../../../services/context/User";
import { InvoiceContextProvider, InvoiceContext } from '../../../services/context/Invoice';
import useAxiosPrivate from '../../../features/hooks/useAxiosPrivate';
import useLogout from '../../../features/hooks/useLogout';

import FormDiscountCode from './formDiscountCode';
import FormUser from './formUser';
import FormNewPassword from './formNewPassword';
import FormInvoice from './formInvoice';

import Head from '../../../layouts/head';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Modal from '../../../components/modal';
import { BannerError, BannerInfo } from '../../../components/banner';

import { SDivider10, SDivider20, SDivider30, SDivider50, SDivider100 } from '../../../styles/dividerStyles';
import { SHrAuto } from '../../../styles/hrStyles';
import { SBtnRed } from '../../../styles/buttonStyles';

export default function AccountData() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('account_data-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        path='account_data'
      />
      <Header/>
      <SDivider50/>
      <MessageContextProvider>
        <UserContextProvider>
          <InvoiceContextProvider>
            <Main/>
          </InvoiceContextProvider>
        </UserContextProvider>
      </MessageContextProvider>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  const { t } = useTranslation();
  const { getErrMsg, getInfoMsg } = useContext(MessageContext);

  return(
    <main className='container-sm'>
      <h2>{t('account_data-h1')}</h2>
      <SDivider10/>

      <BannerError>{getErrMsg()}</BannerError>
      <BannerInfo>{getInfoMsg()}</BannerInfo>

      <FormContent/>

      <SDivider10/>
      <Delete/>
    </main>
  );
}

const FormContent = () => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const { setErrMsg } = useContext(MessageContext);
  const { setUserEmail, setUserDiscountCode, } = useContext(UserContext);
  const {
    setInvoiceFiscalCode,
    setInvoiceName,
    setInvoiceLastName,
    setInvoiceState,
    setInvoiceCountry,
    setInvoiceCity,
    setInvoiceZip,
    setInvoiceAddress,
  } = useContext(InvoiceContext);

  useEffect(() => {
    const getAccount = async () => {
      try {
        setErrMsg("");

        const response = await axiosPrivate.get(`/account`);
        if (response.status === 200) {
          setDataAccount(response.data);
        }
      } catch (err) {
        if (!err?.response) {
          setErrMsg(t('err-server-no_response'));
        }
      }
    }

    getAccount();

    function setDataAccount(data) {
      setUserEmail(data.account.email);
      setUserDiscountCode(data.account.discountCode);
      setInvoiceFiscalCode(data.billingAddress.fiscalCode);
      setInvoiceName(data.billingAddress.name);
      setInvoiceLastName(data.billingAddress.lastName);
      setInvoiceState(data.billingAddress.state);
      setInvoiceCountry(data.billingAddress.country);
      setInvoiceCity(data.billingAddress.city);
      setInvoiceZip(data.billingAddress.zip);
      setInvoiceAddress(data.billingAddress.address);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <FormUser/>
      <Divider/>

      <FormNewPassword/>
      <Divider/>

      <FormDiscountCode/>
      <Divider/>

      <FormInvoice/>
      <Divider/>
    </div>
  )
}

const Delete = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const logout = useLogout();
  const [visibled, setVisibled] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const deleteAccount = async () => {
    try {
      setErrMsg("");

      const response = await axiosPrivate.delete(`/account`);
      if (response.status === 204) {
        logout();
        navigate(t('url-home'));
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg(t('err-server-no_response'));
      }
    }
  }

  return (
    <>
      <h3>{t('account_data-delete_h1')}</h3>
      <SDivider10/>

      <p>{t('account_data-delete_p')}</p>
      <SDivider20/>

      <SBtnRed onClick={() => setVisibled(true)}>{t('account_data-delete_btn')}</SBtnRed>

      <BannerError>{errMsg}</BannerError>

      <Modal
        visibled={visibled}
        closeModal={() => setVisibled(!visibled)}
        title={t('account_data-delete-modal')}
        confirmBtnText={t('account_data-delete-modal-confirm')}
        onConfirm={() => deleteAccount()}
        cancelBtnText={t('account_data-delete-modal-cancel')}
        onCancel={() => setVisibled(!visibled)}
      />
    </>
  )
}

const Divider = () => {
  return (
    <>
      <SDivider30/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider30/>
    </>
  )
}