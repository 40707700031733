import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";

import useAxiosPrivate from '../../features/hooks/useAxiosPrivate';
import useAuth from '../../features/hooks/useAuth';
import ROLES from '../../config/rolesList';
import { CartContext } from "../../services/context/Cart";

import Modal from '../../components/modal';
import { BannerError, BannerInfo } from "../../components/banner";

import { SBtnPrimary } from '../../styles/buttonStyles';

export default function Promo() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { getCartDiscount, setCartDiscount } = useContext(CartContext);
  const [infoMsg, setInfoMsg] = useState("");
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const haveDiscountCode = async () => {
      try {
        let response = await axiosPrivate.get(`/account`);
        if (response.status === 200) {
          response = await axiosPrivate.get(`/discounts/code/${response.data.account.discountCode}`);
          if (response.status === 200) {
            setCartDiscount(response.data.discount);
          }
        }
      } catch (err) {
      }
    }

    if (!auth?.roles?.find(role => [ROLES.User].includes(role))) {
      setIsLogged(false);
      return;
    } else {
      setIsLogged(true);
      haveDiscountCode();
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (getCartDiscount()) {
      setInfoMsg(`Sconto del ${getCartDiscount()}% aggiunto al carrello`);
    }
    // eslint-disable-next-line
  }, [getCartDiscount])

  return (
    <>
      { isLogged &&
        <>
          { getCartDiscount() ?
            <>
              <BannerInfo>{infoMsg}</BannerInfo>
            </>
            :
            <ButtonPromo/>
          }
        </>
      }
    </>
  );
}

const ButtonPromo = () => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const { setCartDiscountCode, getCartDiscountCode, setCartDiscount } = useContext(CartContext);
  const [errMsg, setErrMsg] = useState();
  const [visibled, setVisibled] = useState(false);

  const handlerAddDiscountCode = () => {
    setVisibled(true);
  }

  const submitCheckDiscountCode = (e) => {
    e.preventDefault()
    checkDiscountCode();
  }

  const checkDiscountCode = async () => {
    try {
      setErrMsg("");

      if (!getCartDiscountCode()) {
        return;
      }

      const response = await axiosPrivate.get(`/discounts/code/${getCartDiscountCode()}`);
      if (response.status === 204) {
        setErrMsg("Codice non valido");
      } else {
        setCartDiscount(response.data.discount);
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg(t('err-server-no_response'));
      }
    } finally {
      setVisibled(false)
    }
  }

  return (
    <>
      <BannerError>{errMsg}</BannerError>

      <SBtnPrimary type="button" onClick={() => handlerAddDiscountCode()} style={{width: "100%"}}>
        {t('cart-discount-btn')}
      </SBtnPrimary>

      <Modal
        visibled={visibled}
        closeModal={() => setVisibled(!visibled)}
        title={t('cart-discount-modal')}
        onConfirm={() => {checkDiscountCode()}}
        onCancel={() => setVisibled(!visibled)}
        confirmBtnText={t('cart-discount-modal_confirm')}
        cancelBtnText={t('cart-discount-modal_cancel')}
      >
        <form className='container' onSubmit={submitCheckDiscountCode}>
          <input
            type="text"
            id="discountCode"
            onChange={(e) => setCartDiscountCode(e.target.value)}
            maxLength="15"
            required={true}
          />
        </form>
      </Modal>
    </>
  )
}