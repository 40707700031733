import styled from "styled-components";

export const SContainer = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 2fr 1fr;

  @media (max-width: 1500px) {
    grid-template-columns: 2fr 1fr;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 5fr 2fr;
  }

  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

export const STotalOrderRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

export const STotalOrderKey = styled.div`
`;

export const STotalOrderValue = styled.strong`
  text-align: right;
`;

export const SBtnDelete = styled.button`
  border: none;
  padding: 8px;

  font-size: 1.5rem;
  line-height: 0;
  color: red;
  background-color: transparent;
  cursor: pointer;
`;