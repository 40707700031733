import { createContext, useState } from "react";

import {
  isLetters,
  isAlphanumberic,
  isValidZip
} from '../../utils/regex';

export const ShippingContext = createContext(null);

export const ShippingContextProvider = (props) => {
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [validCity, setValidCity] = useState(false);
  const [zip, setZip] = useState();
  const [validZip, setValidZip] = useState(false);
  const [address, setAddress] = useState();
  const [validAddress, setValidAddress] = useState(false);
  const [note, setNote] = useState();

  const setShippingState = (state) => {
    setState(state);
  }
  const getShippingState = () => {
    return state;
  }

  const setShippingCountry = (country) => {
    setCountry(country);
  }
  const getShippingCountry = () => {
    return country;
  }

  const setShippingCity = (city) => {
    setCity(city);
    isLetters(city) ? setValidCity(true) : setValidCity(false);
  }
  const getShippingCity = () => {
    return city;
  }
  const isValidShippingCity = () => {
    return validCity;
  }

  const setShippingZip = (zip) => {
    setZip(zip);
    isValidZip(zip) ? setValidZip(true) : setValidZip(false);
  }
  const getShippingZip = () => {
    return zip;
  }
  const isValidShippingZip = () => {
    return validZip;
  }

  const setShippingAddress = (address) => {
    setAddress(address);
    isAlphanumberic(address) ? setValidAddress(true) : setValidAddress(false);
  }
  const getShippingAddress = () => {
    return address;
  }
  const isValidShippingAddress = () => {
    return validAddress;
  }

  const setShippingNote = (note) => {
    setNote(note);
  }
  const getShippingNote = () => {
    return note;
  }

  const isValidShippingData = () => {
    if ( validCity &&
      validZip &&
      validAddress
    ) {
      return true;
    } else {
      return false;
    }
  }

  const contextValue = {
    setShippingState,
    getShippingState,
    setShippingCountry,
    getShippingCountry,
    setShippingCity,
    getShippingCity,
    isValidShippingCity,
    setShippingZip,
    getShippingZip,
    isValidShippingZip,
    setShippingAddress,
    getShippingAddress,
    isValidShippingAddress,
    setShippingNote,
    getShippingNote,
    isValidShippingData
  };

  return (
    <ShippingContext.Provider value={contextValue}>
      {props.children}
    </ShippingContext.Provider>
  );
};