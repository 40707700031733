const STRING_TEXT = /^[a-zA-Z0-9\s,.]{0,}$/;
const LETTERS = /^[a-zA-Z\s,.]{1,}$/;
const STRING_ALPHANUMERIC = /^[a-zA-Z0-9\s,.]{1,}$/;

const EMAIL = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const PASSWORD = /^.{8,24}$/;
const AMOUNT = /^(\d+(?:[.,]\d{1,2})?)$/;
const ZIPCODE = /^[a-zA-Z0-9\s]{1,10}$/;

export function isLetters(arg) {
  return LETTERS.test(arg);
}

export function isAlphanumberic(arg) {
  return STRING_ALPHANUMERIC.test(arg);
}

export function isValidEmail(arg) {
  return EMAIL.test(arg);
}

export function isValidPassword(arg) {
  return PASSWORD.test(arg);
}

export function isValidFiscalCode(arg) {
  return STRING_TEXT.test(arg);
}

export function isValidAmount(arg) {
  return AMOUNT.test(arg);
}

export function isValidZip(arg) {
  return ZIPCODE.test(arg);
}