import React from 'react';
import { useTranslation } from "react-i18next";

import Head from '../../../layouts/head';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { SDivider30, SDivider100 } from '../../../styles/dividerStyles';

export default function AccountCreated() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('account_created-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
      />
      <Header/>
      <SDivider30/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  const { t } = useTranslation();

  return(
    <main className='container-sm text-center'>
      <h1>{t('account_created-h1')}</h1>
      <SDivider30/>

      <p>
        {t('account_created-main_1')}<br/>
        <strong>{t('account_created-main_2')}</strong>
      </p>
    </main>
  );
}