import React from "react";
import { Routes, Route } from "react-router-dom";
import i18next from 'i18next';
import { getLangCodeFromUrl } from "./features/getCodeLang";
import url from './config/url.json';

import ROLES from './config/rolesList';
import PersistLogin from './features/PersistLogin';
import RequireAuth from './features/RequireAuth';

import Home from './pages/home';
import Contact from "./pages/contact";
import PolicyPrivacy from "./pages/policyPrivacy";

import OliveOil from "./pages/productsTab/olive";
import TropicFruit from "./pages/productsTab/tropic";
import Market from "./pages/market";
import Cart from "./pages/cart";
import PaymentSuccess from "./pages/checkout/success";
import PaymentCancel from "./pages/checkout/cancel";
import Login from "./pages/auth/login";
import SignUp from "./pages/auth/signUp";
import AccountCreated from "./pages/auth/account/created";
import ActiveAccount from "./pages/auth/account/active";
import RecoveryAccount from "./pages/auth/account/recovery";
import ResetPassword from "./pages/auth/account/resetPassword";
import DataAccount from "./pages/account/data";
import Order from "./pages/account/order";

export default function AppRoutes() {
  const languageCode = getLangCodeFromUrl();
  document.documentElement.lang = languageCode;
  i18next.changeLanguage(languageCode);

  return (
    <Routes>
      <Route element={<PersistLogin/>}>
        <Route index element={<Home/>} />

        { /* it */ }
        <Route path={url.it.home} element={<Home/>} />
        <Route path={url.it.contact} element={<Contact/>} />
        <Route path={url.it.privacy} element={<PolicyPrivacy/>} />
        <Route path={url.it.olive_oil} element={<OliveOil/>} />
        <Route path={url.it.tropic_fruit} element={<TropicFruit/>} />
        <Route path={url.it.market} element={<Market/>} />
        <Route path={url.it.cart} element={<Cart/>} />
        <Route path={url.it.payment_success} element={<PaymentSuccess/>} />
        <Route path={url.it.payment_cancel} element={<PaymentCancel/>} />
        <Route path={url.it.login} element={<Login/>} />
        <Route path={url.it.sign_up} element={<SignUp/>} />
        <Route path={url.it.account_created} element={<AccountCreated/>} />
        <Route path={`${url.it.active_account}/:code`} element={<ActiveAccount/>} />
        <Route path={`${url.it.reset_password}/:code`} element={<ResetPassword/>} />
        <Route path={url.it.recovery_account} element={<RecoveryAccount/>} />

        { /* en */ }
        <Route path={url.en.home} element={<Home/>} />
        <Route path={url.en.contact} element={<Contact/>} />
        <Route path={url.en.privacy} element={<PolicyPrivacy/>} />
        <Route path={url.en.olive_oil} element={<OliveOil/>} />
        <Route path={url.en.tropic_fruit} element={<TropicFruit/>} />
        <Route path={url.en.market} element={<Market/>} />
        <Route path={url.en.cart} element={<Cart/>} />
        <Route path={url.en.payment_success} element={<PaymentSuccess/>} />
        <Route path={url.en.payment_cancel} element={<PaymentCancel/>} />
        <Route path={url.en.login} element={<Login/>} />
        <Route path={url.en.sign_up} element={<SignUp/>} />
        <Route path={url.en.account_created} element={<AccountCreated/>} />
        <Route path={`${url.en.active_account}/:code`} element={<ActiveAccount/>} />
        <Route path={`${url.en.reset_password}/:code`} element={<ResetPassword/>} />
        <Route path={url.en.recovery_account} element={<RecoveryAccount/>} />

        { /* Require Auth */ }
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          { /* it */ }
          <Route path={url.it.data_account} element={<DataAccount/>} />
          <Route path={url.it.order} element={<Order/>} />

          { /* en */ }
          <Route path={url.en.data_account} element={<DataAccount/>} />
          <Route path={url.en.order} element={<Order/>} />
        </Route>

        { /* Missing page */ }
        <Route path="/*" element={<Home/>} />
      </Route>
    </Routes>
  );
};