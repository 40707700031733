import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

import { UserContextProvider, UserContext } from "../../../services/context/User";
import { InvoiceContextProvider, InvoiceContext } from '../../../services/context/Invoice';

import { apiSignUp } from '../../../services/api/auth';

import FormUser from './formUser';
import FormPassword from './formPassword';
import FormInvoice from './formInvoice';

import Head from '../../../layouts/head';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { BannerError } from '../../../components/banner';

import { SDivider10, SDivider30, SDivider40, SDivider50, SDivider100 } from '../../../styles/dividerStyles';
import { SHrAuto } from '../../../styles/hrStyles';
import { SBtnPrimary } from '../../../styles/buttonStyles';

export default function SignUp() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('sign_up-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        description={t('sign_up-description')}
        keyword={t('sign_up-keyword')}
        path='sign_up'
      />
      <Header/>
      <SDivider30/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Main = () => {
  const { t } = useTranslation();

  return (
    <main className='container'>
      <h1>{t('sign_up-h1')}</h1>
      <SDivider10/>
      <p>{t('sign_up-main-1')}</p>
      <SDivider40/>

      <Form/>

      <Divider/>

      <div className="text-center">
        <Link to={t('url-login')}>{t('sign_up-main-2')}</Link>
      </div>
    </main>
  );
}

const Form = () => {
  return (
    <UserContextProvider>
      <InvoiceContextProvider>
        <FormContent/>
      </InvoiceContextProvider>
    </UserContextProvider>
  )
}

const FormContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [reCaptcha, setReCaptcha] = useState();
  const {
    getUserEmail,
    getUserPassword,
    isValidUserData
  } = useContext(UserContext);
  const {
    getInvoiceFiscalCode,
    getInvoiceName,
    getInvoiceLastName,
    getInvoiceState,
    getInvoiceCountry,
    getInvoiceCity,
    getInvoiceZip,
    getInvoiceAddress,
    isValidInvoiceData
  } = useContext(InvoiceContext);

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      setErrMsg("");
      setBtnDisabled(true);

      if (reCaptcha === undefined) {
        setErrMsg(t('err-server-missing_recatcha'))
        return
      }

      if (!isValidUserData() && !isValidInvoiceData()) {
        setErrMsg(t('err-server-bad_fill'));
        return;
      }

      const response = await apiSignUp({
        reCaptcha: reCaptcha,
        account: {
          email: getUserEmail(),
          password: getUserPassword(),
        },
        billingAddress: {
          fiscalCode: getInvoiceFiscalCode(),
          name: getInvoiceName(),
          lastName: getInvoiceLastName(),
          address: {
            state: getInvoiceState(),
            country: getInvoiceCountry(),
            zip: getInvoiceZip(),
            city: getInvoiceCity(),
            address: getInvoiceAddress(),
          }
        }
      });
      if (response.status === 201) {
        navigate(t('url-account_created'));
      }
    } catch (err) {
      if(!err?.response){
        setErrMsg(t('err-server-no_response'));
      } else if (err.response?.status === 400) {
        setErrMsg(t('err-server-bad_fill'));
      } else {
        setErrMsg(t('err-server-registration'));
      }
    } finally {
      setBtnDisabled(false);
    }
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="none">
      <FormUser/>
      <Divider/>
      <FormPassword/>
      <Divider/>
      <FormInvoice/>

      <SDivider50/>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
        onChange={(e) => setReCaptcha(e)}
      />
      <SDivider50/>

      <p className="text-center">{t('sign_up-form-1')}</p>

      <SDivider10/>
      <BannerError aria-live="assertive">{errMsg}</BannerError>
      <SDivider10/>

      <div className="text-center">
        <SBtnPrimary className='width-100' disabled={btnDisabled}>
          {t('sign_up-form-2')}
        </SBtnPrimary>
      </div>
    </form>
  )
}

const Divider = () => {
  return (
    <>
      <SDivider30/>
      <SHrAuto style={{background: "rgb(230, 189, 25)"}}/>
      <SDivider30/>
    </>
  )
}